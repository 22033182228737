import React, { useState } from 'react';
import Slider from 'react-slick';
import './App.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const App = () => (
  <div className="App">
    <Header className="header" />
    <div className="main-content">
      <section id="home"><Home /></section>
      <section id="services"><Services /></section>
      <section id="technologies"><Technologies /></section>
      <section id="about-us"><AboutUs /></section>
      <section id="process"><Process /></section>
      {/* <section id="testimonials"><Testimonials /></section> */}
      <section id="contact"><Contact /></section>
    </div>
    <Footer />
  </div>
);






const Header = () => {
  const [isNavVisible, setNavVisible] = useState(false);

const toggleNavVisibility = () => {
  setNavVisible(!isNavVisible);
};

const hideNav = () => {
  setNavVisible(false);
};

  return (
    <header className="header">
      <div className="logo-container">
        <img src="/logo.png" alt="RAK Analytics Logo" className="logo" />
        <h1 className="title">
        
        </h1>
      </div>
      <div className="hamburger" onClick={toggleNavVisibility}>
  &#9776; {/* Hamburger icon */}
</div>
<nav className={`nav ${isNavVisible ? 'show' : ''}`}>
  <ul>
    <li><a href="#home" onClick={hideNav}>Home</a></li>
    <li><a href="#services" onClick={hideNav}>Services</a></li>
    <li><a href="#technologies" onClick={hideNav}>Technologies</a></li>
    <li><a href="#about" onClick={hideNav}>About Us</a></li>
    <li><a href="#process" onClick={hideNav}>Process</a></li>
    <li><a href="#contact" onClick={hideNav}>Contact</a></li>
  </ul>
</nav>
    </header>
  );
};




const Home = () => (
  <section id="home" className="home">
    <ImageSlider />
    <div className="home-content">
      <h1>Welcome to Our Cutting-Edge Software Services</h1>
      <p>We create high-tech software solutions, meticulously crafted to suit your business needs.</p>
      <button className="cta-button">Get Started</button>
    </div>
  </section>
);

const ImageSlider = () => {
  return (
    <Carousel
      showThumbs={false}
      autoPlay
      infiniteLoop
      interval={3000}
      showArrows={false}
      showStatus={false}
    >
      <div>
        <img src="/image01.jpg" alt="Software development" className="slider-image" />
      </div>
      <div>
        <img src="/image02.jpg" alt="Coding" className="slider-image" />
      </div>
      <div>
        <img src="/image01.jpg" alt="Technology" className="slider-image" />
      </div>
      <div>
        <img src="/image02.jpg" alt="Cloud computing" className="slider-image" />
      </div>
    </Carousel>
  );
};
const Services = () => (
  <section id="services" className="services">
    <h2>Our Services</h2>
    <div className="service-item">
  <h3>Tailored Software Solutions</h3>
  <p>Developing custom software designed to meet your unique business challenges and drive efficiency.</p>
</div>
<div className="service-item">
  <h3>Website and Mobile App Creation</h3>
  <p>Designing adaptive websites and mobile applications that deliver an exceptional user experience.</p>
</div>
<div className="service-item">
  <h3>Cloud-Based Services</h3>
  <p>Providing scalable cloud solutions to boost your operational flexibility and optimize expenses.</p>
</div>
<div className="service-item">
  <h3>Technology Advisory</h3>
  <p>Offering professional technology consulting to guide you through the evolving digital landscape.</p>


    </div>
  </section>
);

const Technologies = () => (
  <section id="technologies" className="technologies">
    <h2>Technologies We Use</h2>
    <div className="tech-icons">
      <div className="tech-item">React</div>
      <div className="tech-item">Node.js</div>
      <div className="tech-item">AWS</div>
      <div className="tech-item">Docker</div>
      <div className="tech-item">Kubernetes</div>
      <div className="tech-item">Python</div>
    </div>
  </section>
);

const AboutUs = () => (
  <section id="about" className="about">
    <h2>About Us</h2>
    <p>We are a dedicated group of software engineers and IT specialists focused on providing top-notch software solutions. Our goal is to equip businesses with innovative technology, helping them reach their objectives and maintain a competitive edge in the industry.</p>

  </section>
);

const Process = () => (
  <section id="process" className="process">
    <h2>Our Process</h2>
    <div className="process-steps">
    <div className="step">
  <h3>1. Research</h3>
  <p>Analyzing your business requirements and establishing the project framework.</p>
</div>
<div className="step">
  <h3>2. Conceptualization</h3>
  <p>Designing a user-focused interface that reflects your brand and goals.</p>
</div>
<div className="step">
  <h3>3. Implementation</h3>
  <p>Building the solution with cutting-edge technologies and industry best practices.</p>
</div>
<div className="step">
  <h3>4. Quality Assurance</h3>
  <p>Verifying the solution for errors and ensuring it meets the highest quality benchmarks.</p>
</div>
<div className="step">
  <h3>5. Launch</h3>
  <p>Releasing the solution and offering continuous support to guarantee its effectiveness.</p>
</div>

    </div>
  </section>
);

// const Testimonials = () => (
//   <section id="testimonials" className="testimonials">
//     <h2>Testimonials</h2>
//     <div className="testimonial-list">
//       <div className="testimonial-item">
//         <p>"The team at Software Service Provider delivered a solution that exceeded our expectations. Their professionalism and expertise are unmatched!"</p>
//         <h4>Client A</h4>
//       </div>
//       <div className="testimonial-item">
//         <p>"Their ability to understand our requirements and provide a tailored solution was impressive. We highly recommend their services."</p>
//         <h4>Client B</h4>
//       </div>
//     </div>
//   </section>
// );

const Contact = () => (
  <section id="contact" className="contact">
    <h2>Contact Us</h2>
    <p>If you're interested in working with us or have any questions, feel free to reach out.</p>
    <p>Mobile no 9900173022</p>
    <p>Mail to info@aravindinfosolutions.com</p>
    <p>Near 95/60, Cave Temple Rd,opp. to Bgs National Public School, Muthurayya Swamy Layout,Hulimavu,Bengalore,Karnataka</p>
  </section>
);

const Footer = () => (
  <footer className="footer">
    <p>2024 Software Service Provider. All Rights Reserved. ©</p>
  </footer>
);

export default App;
